import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DetailsHead from "../components/detailshead"
import PrivacyPolicy from '../components/privacy-policy'

const P = () => (
  <Layout>
    <SEO title="Privacy policy" />
    <DetailsHead title="Privacy policy"/>
    <PrivacyPolicy/>
  </Layout>
)

export default P

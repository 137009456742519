import React from 'react'
import { graphql, useStaticQuery} from "gatsby"
import Markdown from 'react-markdown'

import style from './privacy.module.css'

const P = () => {
	const query = useStaticQuery(graphql`
			query {
				allStrapiPrivacyPolicy {
				    edges {
				      node {
				        name
				        content
				      }
				    }
				}
			}
		`)

		let p = [];
		query.allStrapiPrivacyPolicy.edges.forEach(({node})=>{
			p.push(node)
		})
		p = p[0];
	return (
		<div className={style.container}>
			<div className={style.wrapper}>
				<div className={style.contentWrapper}>
					<Markdown source={p.content} escapeHtml={true}/>
				</div>
			</div>
		</div>
	)
}

export default P;